<script>
import config from '../../../config'
import apiDetailsApi from '../apis/apiDetails'

export default {
  data() {
    return {
      frontendVersion: config.Version,

      busy: false,
      backendVersion: undefined,
      error: undefined,
    }
  },

  created() {
    this.fetchDetails()
  },

  methods: {
    async fetchDetails() {
      try {
        this.busy = true
        const details = await apiDetailsApi.index()
        this.backendVersion = details && details.apiVersion ? details.apiVersion : 'unknown-dev'
        this.error = undefined
      } catch (error) {
        this.error = error
      } finally {
        this.busy = false
      }
    },
  },
}
</script>

<template>
  <v-card :loading="busy">
    <v-card-text class="text-center">
      <div>Frontend version: {{ frontendVersion }}</div>

      <div v-if="error">Error loading backend API details: {{ error }}</div>

      <div v-else>Backend version: {{ backendVersion }}</div>
    </v-card-text>
  </v-card>
</template>
