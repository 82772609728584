<script>
import config from '../../../config'
import ApiDetails from '../components/ApiDetails'
import * as realtime from '@/infrastructure/realtime'
import store from '@/infrastructure/store'

export default {
  components: {
    ApiDetails,
  },

  data() {
    return {
      appName: config.AppName,
    }
  },

  computed: {
    pwaIsInstalled: () => store.state.pwaIsInstalled,
    realtimeIsConnected: () => realtime.state.isConnected,
    realtimeConnectAttempts: () => realtime.state.connectAttempts,
    realtimeConnectedMessage: () => (realtime.state.isConnected ? 'Connected to the realtime server' : 'Not connected to the realtime server'),
    realtimeAttemptsMessage: () => (realtime.state.connectAttempts === 1 ? '1 attempt' : `${realtime.state.connectAttempts} attempts`),
  },
}
</script>

<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="mx-auto">About {{ appName }}</div>
      </v-card-title>
    </v-card>

    <div class="pt-4"></div>

    <v-card v-if="pwaIsInstalled">
      <v-alert text type="success"> App installed on this device</v-alert>
    </v-card>
    <v-card v-else>
      <v-alert text type="warning"> App not yet installed on this device</v-alert>
    </v-card>

    <v-card v-if="realtimeIsConnected">
      <v-alert text type="success">{{ realtimeConnectedMessage }} - ({{ realtimeAttemptsMessage }})</v-alert>
    </v-card>
    <v-card v-else>
      <v-alert text type="warning">{{ realtimeConnectedMessage }} - ({{ realtimeAttemptsMessage }})</v-alert>
    </v-card>

    <ApiDetails />

    <div class="pt-4"></div>

    <v-row>
      <v-col cols="6">
        <v-card :to="{ name: 'privacy-policy' }">
          <v-card-text class="text-center">Privacy Policy</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card :to="{ name: 'terms-of-service' }">
          <v-card-text class="text-center">Terms of Service</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
